import { RisebinarQuery } from "@interfaces/risebinar"
import KyMethod from "@repositories/KyMethod"

export default class RisebinarRepositories extends KyMethod {
  protected async getRawMaterial(materi_id: string) {
    return this._getProtectedData(`/risebinar/${materi_id}/material`)
  }

  protected async getRawCertificate(id_certificate: string) {
    return this._getProtectedData(`/risebinar/${id_certificate}/certificates`)
  }

  protected async getRawVideoRecord(id_video: string) {
    return this._getProtectedData(`/risebinar/${id_video}/video`)
  }

  protected async getRawRisebinarList(query?: Partial<RisebinarQuery>) {
    return this._getData("/risebinars", query)
  }
}
