import { LinkButton, RegularButton } from "@components/button"
import { Avatar } from "@components/misc"
import { Container, FlexBox } from "@components/wrapper"
import { HARISENIN_LOGO } from "@const/pictures"
import { TokenData } from "@interfaces/auth"
import AuthServices from "@services/auth.services"
import clsx from "clsx"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useEffect, useRef, useState } from "react"
import { FaCheckCircle } from "react-icons/fa"
import { MdClose, MdSegment } from "react-icons/md"
import { AccountAccordion, ProgramAccordion, StaticAccordion } from "./mobile"
import { CategoryAccordion } from "./mobile/CategoryAccordion"

interface HeaderMobileProps {
  loginModalHandler: () => void
  isLogin?: boolean | number
  tokenData?: TokenData
  screenHeight: number
}

const HeaderMobile: React.FC<HeaderMobileProps> = ({ loginModalHandler, isLogin, tokenData }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  // const [menuHeight, setMenuHeight] = useState("0px")

  // const [searchOpen, setSearchOpen] = useState(false)
  // const [searchHeight, setSearchHeight] = useState("0px")

  const [unverifiedStatus, setUnverifiedStatus] = useState(false)
  const [isToastOpen, setIsToastOpen] = useState(false)

  const auth = new AuthServices()
  const router = useRouter()
  const contentSpace = useRef<HTMLDivElement>(null)
  // const searchSpace = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!tokenData?.user_active && isLogin) {
      setUnverifiedStatus(true)
    }
  }, [tokenData])

  const sendEmail = async () => {
    try {
      await auth.resendVerificationEmail(tokenData?.user_email as string)
      setIsToastOpen(true)

      setTimeout(() => setIsToastOpen(false), 3000)
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.log(e)
      }
    }
  }

  function handleMenu() {
    const content = contentSpace.current

    if (!contentSpace || !content) {
      return
    }

    setMenuOpen(!menuOpen)

    // if (menuOpen) {
    //   setMenuHeight("0px")
    // } else {
    //   const currentHeight = screenHeight - 57
    //   const contentHeight = content.scrollHeight
    //
    //   setMenuHeight(currentHeight < contentHeight ? `${currentHeight}px` : `${contentHeight}px`)
    // }
  }

  // function handleSearchButton() {
  //   const content = searchSpace.current
  //
  //   if (!searchSpace || !content) {
  //     return
  //   }
  //
  //   setSearchOpen(!searchOpen)
  //
  //   if (searchOpen) {
  //     setSearchHeight("0px")
  //   } else {
  //     const currentHeight = screenHeight - 57
  //     const contentHeight = content.scrollHeight
  //
  //     setSearchHeight(currentHeight < contentHeight ? `${currentHeight}px` : `${contentHeight}px`)
  //   }
  // }

  async function logout() {
    await auth.logout()
    router.reload()
  }

  return (
    <>
      <Container
        className={clsx(
          "flex justify-between items-center ",
          "py-3 bg-white sticky top-0 z-[10] sm:hidden"
        )}
      >
        <div className="wh-8">
          {isLogin && (
            <Avatar alt="avatar" src={tokenData?.user_picture ?? ""} size={1000} className="wh-8" />
          )}
        </div>

        <Link href="/" passHref={true}>
          <Image
            width={100}
            height={25}
            src={HARISENIN_LOGO}
            alt="harisenin.com logo"
            className="h-3.5"
          />
        </Link>

        <FlexBox className="">
          <button onClick={handleMenu}>
            {menuOpen ? <MdClose className="text-xl " /> : <MdSegment className="text-xl " />}
          </button>
        </FlexBox>
      </Container>

      {unverifiedStatus ? (
        <div className="fixed z-10" >
          <div className="bg-[#FEDE00] p-2 text-center text-black">
            Silahkan cek email Anda untuk melakukan verifikasi atau{" "}
            <u className="cursor-pointer underline text-new-blue" onClick={sendEmail}>
              klik di sini
            </u>{" "}
            untuk kirim ulang email verifikasi.
          </div>
        </div>
      ) : null}

      <FlexBox
        justify="center"
        align="center"
        className={clsx(
          "z-[1000] fixed top-[50px] left-1/2 w-[300px] ml-[-150px] bg-green text-white rounded text-center p-3 text-sm",
          isToastOpen ? "visible show_toast" : "invisible"
        )}
      >
        <FaCheckCircle className="mr-2" /> Email Verifikasi berhasil dikirim
      </FlexBox>

      {/* Search Box*/}
      {/*<div*/}
      {/*  ref={searchSpace}*/}
      {/*  style={{ maxHeight: searchOpen ? `${searchHeight}` : "0px" }}*/}
      {/*  className={clsx(*/}
      {/*    container,*/}
      {/*    "shadow-lg overflow-hidden transition-max-menuHeight duration-700 ease-in-out sticky top-[55px] z-[10] bg-white"*/}
      {/*  )}*/}
      {/*>*/}
      {/*  <div className="h-[18px]" />*/}
      {/*  <FlexBox className="bg-primary-grey rounded p-2.5" align="center">*/}
      {/*    <FaSearch className="text-green mr-3 text-sm" />*/}
      {/*    <input*/}
      {/*      type="text"*/}
      {/*      placeholder="Cari"*/}
      {/*      className="focus:outline-none focus:shadow-outline text-sm bg-transparent"*/}
      {/*    />*/}
      {/*  </FlexBox>*/}
      {/*  <div className="h-[18px]" />*/}
      {/*</div>*/}

      {/* Menu Box */}
      <div
        ref={contentSpace}
        className={clsx(
          "shadow-lg overflow-auto transition-max-height duration-700 ease-in-out sticky top-[54px] z-[10] bg-white"
        )}
        style={{
          height: menuOpen ? "initial" : "0px",
          maxHeight: menuOpen ? "calc(100vh - 54px)" : 0,
        }}
      >
        <ProgramAccordion  />
        <StaticAccordion  />
        {isLogin && <AccountAccordion/>}

        <div className="p-4">
          {!isLogin && (
            <FlexBox className="gap-3">
              <RegularButton
                className="w-full !rounded-lg"
                onClick={loginModalHandler}
                customPadding="py-3 px-4"
                variant="transparent-blue"
                customTextSize="text-xs"
              >
                Masuk
              </RegularButton>

              <LinkButton
                href={"/register"}
                variant="new-blue"
                className="w-full !rounded-lg"
                customPadding="py-3 px-4"
                customFontSize="text-xs"
              >
                Daftar{" "}
              </LinkButton>
            </FlexBox>
          )}

          {isLogin && (
            <RegularButton
              onClick={logout}
              variant="transparent-red"
              className="w-full !rounded-lg"
              customPadding="py-3 px-4"
              customTextSize="text-xs"
            >
              Logout
            </RegularButton>
          )}
        </div>
      </div>
    </>
  )
}

export default HeaderMobile
