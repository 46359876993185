import { Course, CourseCategory } from "@interfaces/course"
import { NewPaginationData } from "@interfaces/misc"
import { Result } from "@lib/api/services/response"
import CourseRepositories from "../repositories/course.repositories"

export default class CourseServices extends CourseRepositories {
  async getCourseList(query: any) {
    try {
      const res = await this.getRawCourses(query)

      const response = await this._getResponse<NewPaginationData<Course>>(res)

      if (response.error) {
        return Result.fail<NewPaginationData<Course>>()
      }

      const result = response.result

      return Result.ok<NewPaginationData<Course>>(result)
    } catch (e) {
      return Result.fail<NewPaginationData<Course>>()
    }
  }

  async getCategoryList(type: string) {
    try {
      const res = await this.getRawCategories(type)

      const response = await this._getResponse<CourseCategory[]>(res)

      if (response.error || !response.result) {
        return Result.fail<CourseCategory[]>()
      }

      return Result.ok<CourseCategory[]>(response.result)
    } catch (e) {
      return Result.fail<CourseCategory[]>()
    }
  }
}
