import { NewPaginationData } from "@interfaces/misc"
import { School, SchoolType, Testimonial } from "@interfaces/school"
import { Result } from "@services/response"
import BootcampRepositories from "../repositories/bootcamp.repositories"

export default class BootcampServices extends BootcampRepositories {
  async getSchoolList(type: SchoolType, limit = 6) {
    try {
      const res = await this.getRawBootcamp(type.toLowerCase(), limit)
      const response = await this._getResponse<NewPaginationData<School>>(res)

      if (response.error) {
        return Result.fail<NewPaginationData<School>>()
      }

      return Result.ok<NewPaginationData<School>>(response.result)
    } catch (e) {
      return Result.fail<NewPaginationData<School>>()
    }
  }
}
