import { NewPaginationData, PaginationData } from "@interfaces/misc"
import { Risebinar, RisebinarQuery } from "@interfaces/risebinar"
import RisebinarRepositories from "@repositories/risebinar.repositories"
import { Result } from "@services/response"

export default class RisebinarServices extends RisebinarRepositories {
  async getRisebinarList(query?: Partial<RisebinarQuery>) {
    try {
      const res = await this.getRawRisebinarList(query)
      const response = await this._getResponse<NewPaginationData<Risebinar>>(res)

      if (response.error) {
        return Result.fail<NewPaginationData<Risebinar>>()
      }

      return Result.ok<NewPaginationData<Risebinar>>(response.result)
    } catch (e) {
      return Result.fail<NewPaginationData<Risebinar>>()
    }
  }

  async downloadMateri(materi_id: string) {
    try {
      const res = await this.getRawMaterial(materi_id)

      const response = await this._getResponse<any>(res)

      if (response.error) {
        return Result.fail<PaginationData<any>>()
      } else {
        return Result.ok<any>(response.result)
      }
    } catch (e: any) {
      return Result.fail<PaginationData<any>>()
    }
  }

  async downloadCertificate(certificate_id: string) {
    try {
      const res = await this.getRawCertificate(certificate_id)

      const response = await this._getResponse<any>(res)

      if (response.error) {
        return Result.fail<PaginationData<any>>()
      } else {
        return Result.ok<any>(response.result)
      }
    } catch (e: any) {
      return Result.fail<PaginationData<any>>()
    }
  }

  async videoRecord(id_video: string) {
    try {
      const res = await this.getRawVideoRecord(id_video)

      const response = await this._getResponse<any>(res)

      if (response.error) {
        return Result.fail<PaginationData<any>>()
      } else {
        return Result.ok<any>(response.result)
      }
    } catch (e: any) {
      return Result.fail<PaginationData<any>>()
    }
  }
}
