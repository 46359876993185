import React from "react"

import HeaderPopup from "./HeaderPopup"
import { container } from "@components/wrapper"
import clsx from "clsx"
import { LinkHead } from "../HeaderComponent"

export function CorporatePopover({ setIsHover }: any) {
  const PopoverComponent = () => {
    return (
      <div className={clsx(container, "text-black")}>
        <div className="mt-4 text-2xl font-medium">Corporate</div>
        <div className="flex gap-12 mt-4 ">
          <div className="w-2/5">
            Dapatkan solusi tepat dalam memperkuat bisnis Anda bersama tim dengan skill terbaik.
          </div>
          <div className="grid grid-cols-2 gap-4 w-1/2">
            <LinkHead link="/hire-graduates">Hire Graduates</LinkHead>
            <LinkHead link="/corporate-training">Corporate Training</LinkHead>
          </div>
        </div>
      </div>
    )
  }

  return (
    <HeaderPopup setIsHover={setIsHover} panel={<PopoverComponent />} buttonLabel="Corporate" />
  )
}
