import { Disclosure } from '@headlessui/react'
import React from 'react'
import { AccordionHead, AccordionPanel, MobileLink } from "."

export const StaticAccordion = () => {
  return (
    <div>
      <Disclosure>
        {({ open }) => (
          <div className="grid gap-2">
            <AccordionHead open={open}>Corporate</AccordionHead>
            <AccordionPanel>
              <MobileLink link="/hire-graduates">Hire Graduates</MobileLink>
              <MobileLink link="/corporate-training">Corporate Training</MobileLink>
            </AccordionPanel>
          </div>
        )}
      </Disclosure>
      <Disclosure>
        {({ open }) => (
          <div>
            <AccordionHead open={open}>About</AccordionHead>
            <AccordionPanel>
              <MobileLink link="/tentang-kami">Tentang Kami</MobileLink>
              <MobileLink link="/alumni">Alumni</MobileLink>
            </AccordionPanel>
          </div>
        )}
      </Disclosure>
    </div>
  )
}
