import { Disclosure } from '@headlessui/react'
import React from 'react'
import { AccordionHead, AccordionPanel, MobileLink } from '.'

const LINKS = [
  {
    label: "Profil",
    slug: "/dashboard/profil",
  },
  {
    label: "Daftar Pembelian",
    slug: "/dashboard/order",
  },
  {
    label: "Referral Program",
    slug: "/dashboard/referral",
  },
  {
    label: "Kelas Video Course",
    slug: "/learning/dashboard/progress",
  },
]

export const AccountAccordion = () => {
  return (
    <div>
      <Disclosure>
        {({ open }) => (
          <div className="grid gap-2">
            <AccordionHead open={open}>Akun</AccordionHead>
            <AccordionPanel>
              {LINKS.map((link, i) => (
                <MobileLink link={link.slug} key={i}>{link.label}</MobileLink>
              ))}
            </AccordionPanel>
          </div>
        )}
      </Disclosure>
    </div>
  )
}
