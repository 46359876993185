import React, { useEffect, useState } from "react"
import { useScreenSize } from "@lib/hooks"
import { useUserData } from "@lib/context/ComponentContext"
import AuthServices from "@services/auth.services"
import clsx from "clsx"
import { container, FlexBox } from "@components/wrapper"
import { HARISENIN_LOGO, HARISENIN_LOGO_WHITE } from "@const/pictures"
import { FaCheckCircle } from "react-icons/fa"
import { HeaderComponent } from "./components"
import { LoginModal } from "@components/modal"
import HeaderMobile from "./components/HeaderMobile"
import Image from "next/image"
import Link from "next/link"
import { useAnalytics } from "@lib/context/AnalyticsContext"
import { destroyCookie } from "nookies"
import { useRouter } from "next/router"

interface HeaderProps {
  transparent?: boolean
  isNotSticky?: boolean
  bgOnly?: boolean
}

export function Header({ transparent, isNotSticky, bgOnly }: HeaderProps) {
  const [loginModal, setLoginModal] = useState(false)
  const [unverifiedStatus, setUnverifiedStatus] = useState(false)
  const [isToastOpen, setIsToastOpen] = useState(false)
  const [isHover, setIsHover] = useState<any>(false)

  const { deviceType, screenHeight } = useScreenSize()
  const { tokenData, isLogin } = useUserData()
  const auth = new AuthServices()
  const { analytics } = useAnalytics()
  const { query } = useRouter()

  useEffect(() => {
    const initIdentify = async () => {
      try {
        const res = await auth.getUserData()

        if (res.isSuccess) {
          const result = res.getValue()
          analytics.identify(tokenData?.id, result)
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (!tokenData?.user_active && isLogin) {
      setUnverifiedStatus(true)
    }

    if (query?.authenticated) {
      destroyCookie(null, "HS_TEMP")
    }

    if (isLogin) {
      initIdentify()
    }
  }, [tokenData])

  const handleOpenLogin = () => {
    setLoginModal(true)
  }
  const handleCloseLogin = () => {
    setLoginModal(false)
  }

  const sendEmail = async () => {
    try {
      await auth.resendVerificationEmail(tokenData?.user_email as string)
      setIsToastOpen(true)

      setTimeout(() => setIsToastOpen(false), 3000)
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.log(e)
      }
    }
  }

  return (
    <>
      {deviceType === "web" ? (
        <div
          className={clsx(
            "top-0 z-[1000]",
            isHover && "bg-white !text-black",
            isNotSticky ? "fixed" : "sticky",
            transparent && !bgOnly && "text-white",
            transparent ? `w-full fixed transition ease-in delay-150 ` : "bg-white w-full"
          )}
        >
          {/* Header Component */}
          <div className="static">
            <FlexBox align="center" justify="between" className={clsx(container, "py-4")}>
              {/* Logo */}
              <FlexBox align="center">
                <Link href="/" passHref={true}>
                  <Image
                    src={transparent && !bgOnly && !isHover ? HARISENIN_LOGO_WHITE : HARISENIN_LOGO}
                    alt="harisenin.com logo"
                    className="h-[25px] w-auto"
                    width={100}
                    height={25}
                  />
                </Link>
              </FlexBox>

              <HeaderComponent
                loginModalHandler={handleOpenLogin}
                isLogin={isLogin}
                tokenData={tokenData}
                setIsHover={(e) => setIsHover(e)}
              />
            </FlexBox>
          </div>

          {unverifiedStatus && (
            <div className="bg-[#FEDE00] py-2 text-center text-black">
              Silahkan cek email Anda untuk melakukan verifikasi atau{" "}
              <u className="cursor-pointer underline text-new-blue" onClick={sendEmail}>
                klik di sini
              </u>{" "}
              untuk kirim ulang email verifikasi.
            </div>
          )}

          <FlexBox
            justify="center"
            align="center"
            className={clsx(
              "z-[1000] fixed top-[50px] left-1/2 w-[300px] ml-[-150px] bg-green text-white rounded text-center p-3 lg:text-base text-sm gap-2",
              isToastOpen ? "visible show_toast" : "invisible"
            )}
          >
            <FaCheckCircle /> Email Verifikasi berhasil dikirim
          </FlexBox>
        </div>
      ) : (
        <HeaderMobile
          loginModalHandler={handleOpenLogin}
          isLogin={isLogin}
          tokenData={tokenData}
          screenHeight={screenHeight}
        />
      )}

      <LoginModal show={loginModal} onClose={handleCloseLogin} />
    </>
  )
}
