import { CourseCategory } from "@interfaces/course"
import CourseServices from "@lib/api/services/course.services"
import { useLayoutEffect, useState } from "react"

export function useCourseCategory() {
  const [data, setData] = useState<CourseCategory[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const course = new CourseServices()

  useLayoutEffect(() => {
    const fetcher = async () => {
      try {
        const res = await course.getCategoryList("learning")

        if (res.isSuccess) {
          const result = res.getValue()
          setData(result)
        }

        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }

    fetcher()
  }, [])

  return { data, isLoading }
}
