import React, { useEffect, useRef, useState } from "react"
import { Popover } from "@headlessui/react"
import { HiOutlineChevronDown } from "react-icons/hi"
import clsx from "clsx"

interface HeaderPopupProps {
  buttonLabel: string
  panel: React.ReactNode
  panelClassName?: string
  panelContainerClassName?: string
  setIsHover?: any
}

function HeaderPopup({
  buttonLabel,
  panel,
  panelClassName,
  panelContainerClassName,
  setIsHover,
}: HeaderPopupProps) {
  let timeout: NodeJS.Timeout
  const timeoutDuration = 150

  const buttonRef = useRef<HTMLButtonElement>(null)
  const panelRef = useRef<HTMLDivElement>(null)

  const [openState, setOpenState] = useState(false)

  const toggleMenu = (open: boolean) => {
    setOpenState((openState) => !openState)
    buttonRef?.current?.click()
  }

  const onHover = (open: boolean, action: "onMouseEnter" | "onMouseLeave") => () => {
    if (
      (!open && !openState && action === "onMouseEnter") ||
      (open && openState && action === "onMouseLeave")
    ) {
      setIsHover(!open)
      clearTimeout(timeout)
      timeout = setTimeout(() => toggleMenu(open), timeoutDuration)
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (buttonRef.current) {
      event.stopPropagation()
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })

  useEffect(() => {
    const panelHandler = () => {
      if (!panelRef) {
        return
      }

      if (panelRef.current) {
        // setPanelWidth(panelRef.current.offsetWidth)
      }
    }

    panelHandler()
  }, [panelRef])

  return (
    <Popover>
      {({ open }) => (
        <div
          onMouseEnter={onHover(open, "onMouseEnter")}
          onMouseLeave={onHover(open, "onMouseLeave")}
          // className="flex flex-colp relative"
        >
          <Popover.Button
            ref={buttonRef}
            className="relative focus-visible:ring-0 focus-visible:outline-0"
          >
            <div
              className={clsx(
                "font-medium cursor-pointer flex items-center gap-3 relative",
                "focus-visible:ring-0 focus-visible:outline-0",
                open && "text-green font-semibold underline underline-offset-8"
              )}
            >
              {buttonLabel}
              {/* <HiOutlineChevronDown className="font-md font-semibold" /> */}
            </div>
          </Popover.Button>

          {true && (
            <Popover.Panel
              className={clsx(
                "z-10 absolute top-10 pt-8 left-0 w-screen drop-shadow-b-md",
                panelContainerClassName
              )}
            >
              <div ref={panelRef} className={clsx(panelClassName)}>
                <div className="bg-white py-8">{panel}</div>
                <div
                  className="bg-black h-screen opacity-50"
                  onMouseEnter={onHover(open, "onMouseLeave")}
                />
              </div>
            </Popover.Panel>
          )}
        </div>
      )}
    </Popover>
  )
}

export default HeaderPopup
