import React from "react"
import { useCourseCategory } from "@lib/hooks/data/useCourse"
import { useRisebinar, useSchools } from "@hooks/data"
import { AccordionHead, AccordionPanel, MobileLink } from "."
import { Disclosure } from "@headlessui/react"
import clsx from "clsx"
import { FaChevronDown } from "react-icons/fa"
import { FiDivideCircle } from "react-icons/fi"

const Head = ({ children, open }: { children: any; open: boolean }) => {
  return (
    <Disclosure.Button
      className={clsx(
        "ont-semibold text-sm w-full pl-3",
        "flex justify-between items-center font-urbanist"
      )}
    >
      <span>{children}</span>
      <FaChevronDown className={clsx("rotate-animation", open && "rotate-animation__up")} />
    </Disclosure.Button>
  )
}

const Panel = ({ children }: { children: any }) => {
  return (
    <div className={clsx("-ml-5")}>
      {children}
    </div>
  )
}

export const ProgramAccordion = () => {
  const { data: courses, isLoading: isFetchingCourses } = useCourseCategory()
  const { data: schools, isLoading: isFetchingSchools } = useSchools("BOOTCAMP")
  const { data: proClass, isLoading: isFetchingProClass } = useSchools("PRO_CLASS")
  const { data: risebinars } = useRisebinar()

  return (
    <Disclosure>
      {({ open }) => (
        <div>
          <AccordionHead open={open}>Program</AccordionHead>
          <AccordionPanel>
            <div className="py-4 grid gap-5">
              {Boolean(schools.length) && (
                <Disclosure>
                  {({ open: open1 }) => (
                    <div className="grid gap-3">
                      <Head open={open1}>Bootcamp</Head>
                      <Panel>
                        <AccordionPanel>
                          {schools.map((link, i) => (
                            <MobileLink link={`/school/bootcamp/${link.product_slug}`} key={i}>
                              {link.product_name}
                            </MobileLink>
                          ))}
                        </AccordionPanel>
                      </Panel>
                    </div>
                  )}
                </Disclosure>
              )}

              {Boolean(proClass.length) && (
                <Disclosure>
                  {({ open: open2 }) => (
                    <div className="grid gap-3">
                      <Head open={open2}>ProClass</Head>
                      <Panel>
                        <AccordionPanel>
                          {proClass.map((link, i) => (
                            <MobileLink link={`/school/proclass/${link.product_slug}`} key={i}>
                              {link.product_name}
                            </MobileLink>
                          ))}
                        </AccordionPanel>
                      </Panel>
                    </div>
                  )}
                </Disclosure>
              )}

              {Boolean(courses.length) && (
                <Disclosure>
                  {({ open: open3 }) => (
                    <div className="grid gap-3">
                      <Head open={open3}>Video Course</Head>
                      <Panel>
                        <AccordionPanel>
                          {courses.map((link, i) => (
                            <MobileLink link={`/learning/category/${link.category_slug}`} key={i}>
                              {link.category_name}
                            </MobileLink>
                          ))}
                        </AccordionPanel>
                      </Panel>
                    </div>
                  )}
                </Disclosure>
              )}

              {Boolean(risebinars.length) && (
                <Disclosure>
                  {({ open: open4 }) => (
                    <div className="grid gap-3">
                      <Head open={open4}>Event & Workshop</Head>
                      <Panel>
                        <AccordionPanel>
                          {risebinars.map((link, i) => (
                            <MobileLink link={`/risebinar/${link.risebinar_slug}`} key={i}>
                              {link.risebinar_title}
                            </MobileLink>
                          ))}
                        </AccordionPanel>
                      </Panel>
                    </div>
                  )}
                </Disclosure>
              )}
            </div>
          </AccordionPanel>
        </div>
      )}
    </Disclosure>
  )
}
