import RisebinarServices from "@services/risebinar.services"
import { Risebinar } from "@interfaces/risebinar"
import { useLayoutEffect, useState } from "react"

export function useRisebinar() {
  const [data, setData] = useState<Risebinar[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const risebinar = new RisebinarServices()

  useLayoutEffect(() => {
    const fetcher = async () => {
      try {
        const res = await risebinar.getRisebinarList()

        if (res.isSuccess) {
          const result = res.getValue()
          setData(result.data)
        }

        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }

    fetcher()
  }, [])

  return { data, isLoading }
}
