import KyMethod from "../repositories/KyMethod"

export default class CourseRepositories extends KyMethod {
  async getRawCategories(type: string) {
    return this._getData("/categories", { type })
  }

  async getRawCourses(query?: any) {
    return this._getData("/courses", query)
  }
}
