import React, { FC } from "react"
import { useRouter } from "next/router"
import { FaPowerOff } from "react-icons/fa"
import { TokenData } from "@interfaces/auth"
import AuthServices from "@services/auth.services"
import { FlexBox } from "@components/wrapper"
import { Popover } from "@components/popover"
import { Avatar } from "@components/misc"
import { textShortener } from "@lib/functions"
import { BasicLink } from "@components/typography"

interface AccountPopoverProps {
  userData: TokenData
  className?: string
  triggerClassName?: string
  dontShow?: boolean
}

const links = [
  {
    label: "Profil",
    slug: "profil",
  },
  {
    label: "Daftar Pembelian",
    slug: "order",
  },
  {
    label: "Referral Program",
    slug: "referral",
  },
]

export const AccountPopover: FC<AccountPopoverProps> = ({ userData }) => {
  const router = useRouter()
  const auth = new AuthServices()

  async function logout() {
    await auth.logout()
    router.reload()
  }

  const PopoverContent = () => {
    return (
      <FlexBox
        className="bg-white p-5 rounded-lg shadow-[0_12px_32px_0px_rgba(0,0,0,0.2)] text-black"
        direction="col"
      >
        {links.map((value, index) => (
          <a href={`/dashboard/${value.slug}`} className="pb-2.5" key={index}>
            {value.label}
          </a>
        ))}

        <BasicLink href="/learning/dashboard/progress" className="pb-2.5" isExternal>
          Kelas Video Course
        </BasicLink>

        <hr className="mb-4 mt-1.5" />
        <button
          onClick={logout}
          className="text-black font-semibold flex items-center hover:text-red"
        >
          <FaPowerOff className="mr-2" /> Keluar
        </button>
      </FlexBox>
    )
  }

  return (
    <Popover
      triggerClassName="flex items-center py-1.5 cursor-pointer gap-2.5"
      placement="top"
      trigger="hover"
      popover={<PopoverContent />}
      // show
    >
      <Avatar src={userData?.user_picture} alt="" className="wh-9" size={30} />
      <div className="name">{textShortener(userData?.user_firstname, 8)}</div>
    </Popover>
  )
}
