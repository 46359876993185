import React, { FC } from "react"
import { usePopperTooltip } from "react-popper-tooltip"
import { PopoverProps } from "./types"

export const Popover: FC<PopoverProps> = ({
  arrowClassName,
  tooltipClassName,
  triggerClassName,
  triggerId,
  children,
  placement = "auto",
  trigger = "hover",
  popover,
  show,
  arrow,
}) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible, getArrowProps } =
    usePopperTooltip({
      placement,
      trigger,
      visible: show,
      interactive: true,
    })

  return (
    <>
      <div ref={setTriggerRef} className={triggerClassName}>
        {children}
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          id={triggerId ?? "popover-trigger"}
          {...getTooltipProps({
            className: `tooltip-container ${tooltipClassName ?? ""}`,
          })}
        >
          {arrow && (
            <div {...getArrowProps({ className: `tooltip-arrow ${arrowClassName ?? ""}` })} />
          )}
          {popover}
        </div>
      )}
    </>
  )
}
