import { LinkButton, RegularButton } from "@components/button"
import { FlexBox } from "@components/wrapper"
import { TokenData } from "@interfaces/auth"
import React from "react"
import { AboutPopover, AccountPopover, CorporatePopover, ServiceDropdown } from "./popover"

export interface HeaderComponentProps {
  loginModalHandler: () => void
  isLogin?: boolean | number
  tokenData?: TokenData
  setIsHover?: (e: any) => void
}

export const LinkHead = ({ children, link }: { children: any; link: string }) => {
  return (
    <a href={link} className="hover:underline underline-offset-8 hover:font-semibold">
      {children}
    </a>
  )
}

export const HeaderComponent = ({
  loginModalHandler,
  isLogin,
  tokenData,
  setIsHover,
}: HeaderComponentProps) => {
  return (
    <nav className="flex items-center">
      <FlexBox align="center" justify="between" className="gap-8">
        <FlexBox align="center" className="gap-8">
          <ServiceDropdown setIsHover={setIsHover} />
          {/* <VideoPopover /> */}
          <CorporatePopover setIsHover={setIsHover} />
          <AboutPopover setIsHover={setIsHover} />
          {/* <a href="/prakerja" className="flex gap-2 font-medium">
            <Image
              src={`${HARISENIN_PUBLIC_LOGO}/logo_kartu-prakerja.png`}
              width={100}
              height={25}
              className="h-[25px] w-auto"
            />
          </a> */}
        </FlexBox>

        {isLogin ? (
          <AccountPopover userData={tokenData as TokenData} />
        ) : (
          <FlexBox justify="between" align="center" className="gap-3">
            <LinkButton
              href="/register"
              variant="transparent"
              id="btn-regist"
              className="!rounded-none !bg-white"
            >
              Daftar
            </LinkButton>
            <RegularButton onClick={loginModalHandler} id="btn-login" className="!rounded-none">
              Masuk
            </RegularButton>
          </FlexBox>
        )}
      </FlexBox>
    </nav>
  )
}
