import { School, SchoolType } from "@interfaces/school"
import BootcampServices from "@lib/api/services/bootcamp.services"
import { useLayoutEffect, useState } from "react"

export function useSchools(type: SchoolType) {
  const [data, setData] = useState<School[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const school = new BootcampServices()

  useLayoutEffect(() => {
    const fetcher = async () => {
      try {
        const res = await school.getSchoolList(type, 12)

        if (res.isSuccess) {
          const result = res.getValue()
          setData(result.data)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }

    fetcher()
  }, [type])

  return { data, isLoading }
}
