import HeaderPopup from "@components/header/components/popover/HeaderPopup"
import { BasicLink } from "@components/typography"
import { FlexBox, LoadingBox } from "@components/wrapper"
import { useCourseCategory } from "@lib/hooks/data/useCourse"

export const VideoPopover = () => {
  const { data: categories, isLoading } = useCourseCategory()

  const PopoverComponent = () => {
    return (
      <>
        <FlexBox
          className="text-md font-semibold flex items-center mb-4 !text-black-48 gap-3.5"
          align="center"
        >
          Video Course{" "}
          <span className="text-xs h-min bg-red text-white rounded px-3 py-0.5">
            Baru dari harisenin.com
          </span>
        </FlexBox>

        {isLoading && <LoadingBox height="h-[200px]" />}

        {!isLoading && (
          <FlexBox className="gap-2" direction="col">
            {categories.map((value, index) => (
              <BasicLink
                href={`/learning/category/${value.category_slug}`}
                key={index}
                isExternal
                className="text-green font-medium hover:font-semibold"
              >
                {value.category_name}
              </BasicLink>
            ))}
          </FlexBox>
        )}
      </>
    )
  }

  if (!categories.length) {
    return <></>
  }

  return <HeaderPopup panel={<PopoverComponent />} buttonLabel="Video Course" />
}
